<template>
    <v-card flat tile class="slide-show-card" color="lightgrey lighten-2" height="auto">
        <v-img v-if="isImage" :src="imageUrl" aspect-ratio="1" class="grey lighten-2" min-height="350" max-height="350" width="100%">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
            </template>
        </v-img>
        <video v-else preload="auto" :key="'video-'+asset.src" max-height="350" width="100%" class="video-player" autoplay loop muted
            playsinline>
            <source :src="asset.src">
        </video>

        <v-card-actions class="w-100" style="display: block;">
            <div class="w-100">
                <v-text-field outlined dense hide-details type="number" @input="$emit('updateTimer', $event)" v-model.number="timer" :label="$t('generic.lang_slideShowDuration').replace('{seconds}', 5)"/>
            </div>
            <div class="w-100">
                <v-btn v-if="isImage" class="ma-1" @click="deleteImage" :loading="deleteLoading" :disabled="deleteLoading" color="error" text>
                    <v-icon>delete</v-icon> {{ $t('generic.lang_delete') }}
                </v-btn>
                <v-btn v-else  class="ma-1" @click="$emit('removeVideo')" color="error" text>
                    <v-icon>delete</v-icon> {{ $t('generic.lang_delete') }}
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { ENDPOINTS } from "@/config";


export default {
    name: "TemplateSlideshowAsset",

    components: {
    },

    props: {
        asset: Object,
        templateId: String
    },

    computed: {
        ...mapGetters({
            imageServerUrl: 'api/auth/imageUrl',
        }),
        isImage() {
            return this.asset.type === 0
        },
        imageUrl(){
            if(this.asset.src?.startsWith('data:image/'))
                return this.asset.src

            return this.imageServerUrl(this.asset.src);
        }
    },

    data() {
        return {
            deleteLoading: false,
            timer: 5,
        }
    },

    methods: {
        deleteImage() {
            this.$emit('remove')
        }
    }
}
</script>
<style scoped>
.slide-show-card{
    border: 1px dashed lightgray;
}
.video-player{
    max-height: 350px;
    min-height: 350px;
    width: 100;
}
</style>
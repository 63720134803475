<template>
  <v-dialog persistent :value="showDialog" max-width="700">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t('generic.lang_uploadImage') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" :disabled="loading">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row justify="center">
          <v-col cols="10" v-if="previewImage">
            <v-img contain :src="previewImage" max-height="350" />
          </v-col>
          <v-col cols="12">
            <v-file-input
                outlined
                show-size
                :label="$t('generic.lang_selectImage')"
                accept="image/png"
                @change="selectImage"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="error" @click="closeDialog" :disabled="loading || !image">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="uploadImage" :loading="loading" :disabled="loading">
          {{ $t('generic.lang_hochladen') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "TemplateImageUploadDialog",

  components: {
  },

  props: {
    showDialog: Boolean,
    template: Object
  },

  data() {
    return {
      image: null,
      previewImage: null,
      loading: false
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.image = null;
        this.previewImage = null;
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    uploadImage() {
      this.$emit('input', this.image)
      this.$emit('imageUploaded')
    },
    async selectImage(image) {
      this.image = await this.getBase64(image);
      this.previewImage = URL.createObjectURL(image);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        if(!file)
          return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
  }
}
</script>
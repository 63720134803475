<template>
    <v-row v-if="template !== null">
        <v-col cols="12">
            <v-card>
                <v-card-title>{{ template.alias }}</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense :label="$t('erp.lang_TemplateName')" v-model="template.alias"
                                outlined></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-alert text color="info" class="text-center mb-0">{{
                                $t("generic.lang_preferableLogoImageDims") }}</v-alert>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" @click="showLogoUploadDialog = true"><v-icon
                                    style="margin-right: 10px;">backup</v-icon>{{ $t('generic.lang_uploadLogo')
                                }}</v-btn>
                            <v-btn :loading="deleteLogoLoading" :disabled="deleteLogoLoading" @click="deleteLogo"
                                v-if="template.logo !== null" color="error"><v-icon
                                    style="margin-right: 10px;">delete</v-icon>{{ $t('generic.lang_removeLogo')
                                }}</v-btn>
                        </v-col>

                        <v-col v-if="template.logo === null || template.logo.length === 0" cols="9"
                            class="justify-center">
                            <v-alert text color="info" class="text-center"><b>Info:</b> Sie haben noch kein Logo
                                hochgeladen.<br>Es wird das 3POS Kassenlogo verwendet.</v-alert>
                            <v-img height="100" contain src="../../../../assets/images/3pos.svg"></v-img>
                        </v-col>

                        <v-col v-else cols="9" class="justify-center">
                            <v-img height="100" contain :src="logoUrl"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card :disabled="loading" :loading="loading">
                <v-card-title>
                    {{ $t('settings.lang_slideshow_images') }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-alert text color="info" class="text-center mb-0">{{
                                    $t("generic.lang_preferableSlideshowImagesDims") }}</v-alert>
                            </v-col>
                            <v-col cols="12">
                                <div>
                                    <v-btn color="primary" @click="showImageUploadDialog = true">
                                        {{ $t('generic.lang_uploadImage') }}
                                    </v-btn>
                                    <v-btn color="primary" @click="showVideoDialog = true">
                                        {{ $t('generic.lang_addVideo') }}
                                    </v-btn>
                                </div>
                                <v-container fluid>
                                    <draggable :list="template.slideshow_images" :group="{ name: 'blocks'}"
                                        v-bind="dragOptions" class="row row--dense ma-0">
                                        <v-col v-for="(asset, index) in template.slideshow_images" :key="index"
                                            class="d-flex child-flex" cols="6" sm="6" md="4" lg="3" align="center">
                                            <TemplateSlideshowAsset @imageDelete="imageDeleted"
                                                @remove="removeAsset(index)"
                                                @updateTimer="updateTimer($event, index)" :templateId="template.uuid"
                                                v-bind:asset="asset"></TemplateSlideshowAsset>
                                        </v-col>
                                    </draggable>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :loading="loading" :disabled="loading" @click="saveTemplate">{{
                        $t('generic.lang_save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-dialog persistent v-model="showVideoDialog" max-width="700">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('generic.lang_uploadImage') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showVideoDialog = false" :disabled="loading">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="10">
                            <video preload="auto" :key="'vbanner-video-'+videoUrl" @canplay="canPlay" height="300"
                                autoplay loop muted class="w-100" playsinline>
                                <source :src="videoUrl" v-on:error="onVideoError">
                            </video>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="videoUrl" :rules="[]" dense outlined show-size label="Video url" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="error" @click="showVideoDialog = false">{{
                        $t('generic.lang_cancel') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :loading="loading" :disabled="loading || videoError || !videoUrl"
                        @click="addVideoAsset">
                        {{ $t('generic.lang_hochladen') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <TemplateLogoUploadDialog :show-dialog="showLogoUploadDialog" :template="template" @imageUploaded="logoUploaded"
            @closeDialog="showLogoUploadDialog = false"></TemplateLogoUploadDialog>
        <TemplateImageUploadDialog v-if="showImageUploadDialog" v-model="currentImage" :show-dialog="showImageUploadDialog" :template="template"
            @imageUploaded="addAsset(2)" @closeDialog="showImageUploadDialog = false">
        </TemplateImageUploadDialog>
    </v-row>
</template>

<script>
import { ENDPOINTS } from "@/config";
import TemplateImageUploadDialog from "./TemplateImageUploadDialog";
import TemplateLogoUploadDialog from "./TemplateLogoUploadDialog";
import { mapState } from "vuex";
import TemplateSlideshowAsset from "./TemplateSlideshowAsset.vue";
import draggable from "vuedraggable";

export default {
    name: "ImagesTemplate",

    components: {
        draggable,
        TemplateLogoUploadDialog,
        TemplateImageUploadDialog,
        TemplateSlideshowAsset
    },

    data() {
        return {
            template: null,
            videoUrl: null,
            loading: false,
            deleteLogoLoading: false,
            videoError: false,
            showLogoUploadDialog: false,
            showVideoDialog: false,
            currentImage: null,
            showImageUploadDialog: false
        }
    },

    watch:{
        showVideoDialog(v){
            if(v){
                this.videoUrl = null
            }
        }
    },

    mounted() {
        this.getTemplate();
    },

    computed: {
        ...mapState([
            'api'
        ]),
        logoUrl() {
            if (this.template === null)
                return "";

            return "https://img.3pos.de/unsafe/billsys_" + this.api.auth.posID + "/original/" + this.template.logo + ".png";
        },
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
            };
        },
    },

    methods: {
        triggerFileInput() {
            document.getElementById('assetInput')?.click();
        },
        removeBackgroundImage(asset) {
            asset.src = null;
        },
        updateTimer(value, index){
            this.template.slideshow_images[index].timer = Number(value) || 5;
        },
        onVideoError(){
            this.videoError = true;
        },
        canPlay(){
            this.videoError = false;
        },
        addAsset(type) {
            this.template.slideshow_images.push(type === 1 ? {
                src: this.videoUrl,
                type: 1,
                timer: 10
            } : {
                src: this.currentImage,
                type: 0,
                timer: 10
            });
            this.showImageUploadDialog = false;
            this.showVideoDialog = false;
            this.currentImage = null;
        },
        getBase64(url) {
            return new Promise((resolve, reject) => {
                if (!url) {
                    reject(new Error('No URL provided'));
                    return;
                }
                fetch(url)
                    .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok');
                        return response.blob();
                    })
                    .then(blob => {
                        const img = new Image();
                        img.crossOrigin = 'Anonymous'; // Important for CORS issues

                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0);

                            // Export as PNG Data URL
                            const pngDataUrl = canvas.toDataURL('image/png');
                            resolve(pngDataUrl);
                        };

                        img.onerror = () => reject(new Error('Failed to load image for conversion to PNG'));

                        img.src = URL.createObjectURL(blob);
                    })
                    .catch(error => reject(error));
            });
        },
        getTemplate() {
            this.loading = true;

            this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.GET, {
                slideID: this.$route.params.id
            }).then(async (res) => {
                if (res.data.success) {
                    this.template = res.data.template;
                    if(!this.template.slideshow_images || !Array.isArray(this.template.slideshow_images)){
                        this.template.slideshow_images = [];
                    }

                    for (let i=0; i < this.template.slideshow_images.length; i++){
                        if(this.template.slideshow_images[i].type === 0){
                            this.template.slideshow_images[i].src = await this.getBase64("https://img.3pos.de/unsafe/billsys_"+this.api.auth.posID+"/original/"+this.template.slideshow_images[i].src);
                        }
                    }
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        saveTemplate() {
            this.loading = true;

            this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.UPDATE, {
                templateID: this.template.uuid,
                alias: this.template.alias,
                slideshow_images: this.template.slideshow_images, 
            }).then((res) => {
                if (res.data.success) {
                    this.$router.push('/kiosk/images-template');
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        logoUploaded() {

            this.showLogoUploadDialog = false;
            this.getTemplate();
        },
        addVideoAsset() {
            this.template.slideshow_images.push({
                src: this.videoUrl,
                type: 1,
                timer: 5
            });
            this.showVideoDialog = false;
        },
        imageUploaded() {

            this.showImageUploadDialog = false;
            this.getTemplate();
        },
        imageDeleted() {

            this.getTemplate();
        },
        removeAsset(index){
            this.template.slideshow_images.splice(index, 1);
        },
        deleteLogo() {
            this.deleteLogoLoading = true;

            this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.LOGO.DELETE, {
                slideID: this.template.uuid
            }).then((res) => {
                if (res.data.success) {

                    this.getTemplate();
                }
            }).catch(() => {

            }).finally(() => {
                this.deleteLogoLoading = false;
            })
        }
    }
}
</script>